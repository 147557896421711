<template>
  <CaseList propTitle="案件管理" :propCaseMode="case_mode"></CaseList>
</template>
<script>
import CaseList from "./CaseList.vue";
import { case_mode_enum } from "@/case.js";
export default {
  name: "case-management",
  components: {
    CaseList,
  },
  data: (vm) => ({
    case_mode_enum: case_mode_enum,
    case_mode: case_mode_enum.Manage,
    title: vm.$t("Case Management"),
  }),
};
</script>
